import { CgMathPlus } from "react-icons/cg";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("token");
  const [menu, setMenu] = useState("home");
  const [isMobile, setIsMobile] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <div className="flex justify-between px-6 sm:flex sm:justify-between sm:px-16 md:px-4 border-b-4 border-gray-200 py-3 ">
      <Link to={"/"} className="flex items-center gap-4">
        <img src="../assets/images/favicon.ico" className="w-full" alt="" />
      </Link>

      <ul
        className={
          isMobile
            ? "absolute top-[6%] z-10 right-[0%] bg-white h-screen w-[100%] px-4 flex flex-col justify-center items-center gap-4 cursor-pointer"
            : "hidden leading-tight gap-10 md:flex md:gap-6 nav-menu items-center font-semibold text-gray-800 text-[18px] md:text-[15px] cursor-pointer lg:gap-8"
        }
        onClick={() => setIsMobile(false)}
      >
        <NavLink
          to={"/"}
          onClick={() => setMenu("home")}
          className={`cursor-pointer ${
            menu === "home" ? "text-[#4bc592]" : "text-gray-800"
          }`}
        >
          Home
          {menu === "home" ? (
            <hr
              className={`mt-1 ${
                isMobile ? "w-11" : "w-13"
              } h-[3px] rounded-xl bg-[#4bc592]`}
            />
          ) : null}
        </NavLink>

        {/* <li
          onClick={() => setMenu("settings")}
          className={`cursor-pointer ${
            menu === "settings" ? "text-[#4bc592]" : "text-gray-800"
          }`}
        >
          Settings
          {menu === "settings" ? (
            <hr
              className={`mt-1 ${
                isMobile ? "w-15" : "w-13"
              } h-[3px] rounded-xl bg-[#4bc592]`}
            />
          ) : null}
        </li> */}
        {!user ? (
          <>
            <Link to={"/auth/login"} className="md:ml-6 lg:ml-12">
              SIGN IN{" "}
            </Link>
            <Link to={"/auth/register"} className="text-[#4bc592]">
              REGISTER
            </Link>
          </>
        ) : (
          <Link to={"#"} onClick={handleLogout} className="text-black">
            Logout
          </Link>
        )}

        <div className="md:flex gap-7 items-center">
          <hr className="hidden md:block md:h-6 w-[1px] bg-gray-300" />
          <Link
            to={"/chat"}
            className="flex items-center gap-2 border rounded-3xl bg-[#4bc592] text-white text-[16px] font-[400] lg:text-[18px] lg:font-[500] py-[7px] px-3 lg:py-[10px] lg:px-4"
          >
            <CgMathPlus className="w-4 h-4 lg:w-6 lg:h-6" />

            <span>Add Friends</span>
          </Link>
        </div>
      </ul>

      <button
        className="block md:hidden"
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? <FaTimes /> : <FaBars />}
      </button>
    </div>
  );
};

export default Navbar;
