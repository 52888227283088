import React from "react";
import { LoaderIcon } from "react-hot-toast";
import { HiUserAdd } from "react-icons/hi";

const PendingRequests = ({ pendingRequests, loader, addFriend }) => {
  return (
    <div className="bg-[#FAFAFA] rounded-t-2xl rounded-b-2xl p-5">
      {loader ? (
        <div className="flex justify-center">
          <LoaderIcon className="!w-10 !h-10 " />
        </div>
      ) : pendingRequests.length > 0 ? (
        pendingRequests?.map((user) => (
          <div key={user.id} className="mb-5">
            <div className="flex justify-between">
              <div className="flex items-center gap-3">
                <div className="border rounded-full h-10 w-10 bg-[#38BF86] text-white text-center content-center shadow-lg uppercase">
                  {user.first_name.substring(0, 1)}
                  {user.last_name.substring(0, 1)}
                </div>
                <h1 className="text-[#38BF86] font-bold">
                  {user?.first_name} {user?.last_name}{" "}
                </h1>
              </div>
              <button
                onClick={() => addFriend(user.user_name)}
                className="rounded-[25px] px-4 py-2 font-bold bg-[#38BF86] flex justify-center items-center flex-wrap gap-2 text-white"
              >
                Accept Request <HiUserAdd size={20} />
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-400 text-center"> No Pending Request Found</p>
      )}
    </div>
  );
};

export default PendingRequests;
