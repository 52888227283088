export const fetchImageAsBase64 = (imageUrl) => {
  // Create a new promise to handle the image loading
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = (error) => {
      console.log(error);
      reject(error);
    };
    img.src = imageUrl;
  });
};
