import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authAxiosInstance } from "../../utlis/http";

const SignupVerify = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [values, setValues] = useState({
    code: "",
    email: email,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const Verfication = () => {
    authAxiosInstance
      .post(`auth/confirm_signup`, values)
      .then((response) => {
        navigate("/auth/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      className="signup bg-cover bg-center w-full min-h-screen flex items-center justify-center "
      style={{
        backgroundImage: `url('/assets/images/background.png')`,
        minHeight: "100vh",
        backgroundSize: "cover",
        width: "100%",
      }}
    >
      <div className="form-container relative bg-white w-[85%] md:w-[70%] lg:w-[40%] py-5 px-4 md:py-7 md:px-6 lg:py-8 lg:px-7 flex gap-1 md:gap-2 flex-col items-start rounded-xl my-5">
        {/* <div className="profile w-4 h-4 md:w-5 md:h-5 lg:w-8 lg:h-8 rounded-full bg-gray-300"></div> */}
        <h3 className="font-semibold text-[20px] md:2xl lg:text-3xl">
          Email Verfication
        </h3>
        <p className="text-center text-[12px] font-[400] md:text-md lg:text-lg lg:font-[400] text-gray-600 leading-tight lg:leading-tight w-full">
          OTP sent at your email <i>{email}</i>
        </p>
        <div className="w-full">
          <label className="text-gray-600 text-[14px] font-[400] md:text-[16px] lg:text-xl lg:font-[400] mt-1 ">
            OTP
          </label>
          <input
            name="code"
            type="text"
            onChange={handleChange}
            className="border border-1 border-gray-400 rounded-lg w-full py-2 px-2 text-[12px] font-[300] md:py-2 md:px-4 md:text-lg lg:py-3 lg:px-5 lg:text-xl lg:font-[400]"
          />
        </div>

        <button
          onClick={Verfication}
          className="border border-1 border-gray-400 rounded-3xl w-full py-2 text-[15px] font-[400] md:py-2 md:text-[17px] lg:py-3 lg:text-xl lg:font-[400] mt-1 hover:bg-gray-300 hover:text-white hover:font-[500]"
        >
          Continue
        </button>

        {/* <LiaTimesSolid className="absolute top-3 right-2 h-4 w-5 lg:top-5 lg:right-5 lg:h-5 lg:w-6" /> */}
      </div>
    </div>
  );
};

export default SignupVerify;
