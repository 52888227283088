import React, { useState } from "react";
import Footer from "../Components/Footer";
import Map from "../Components/Map";
import Navbar from "../Components/Navbar";
import SearchFeedback from "../Components/SearchFeedback";
import Loader from "../Components/Loader";

const Home = () => {
  const [position, setPosition] = useState({ lat: 40.7128, lng: -74.006 });
  const [resturant, setResturant] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);

  return (
    <div className="relative">
      {/* Loader will be shown when loader is true */}
      {loader && (
        <div className="absolute top-0 left-0 w-full h-full z-50 bg-white/70 backdrop-blur-sm flex items-center justify-center">
          <Loader />
        </div>
      )}

      {/* Content to be blurred when loader is true */}
      <div className={`${loader ? "blur-sm" : ""}`}>
        <Navbar />

        <div className="w-[100%] lg:flex min-h-[85vh]">
          <Map
            position={position}
            setResturant={setResturant}
            resturant={resturant}
            markers={markers}
            setMarkers={setMarkers}
            data={data}
            setData={setData}
          />
          <SearchFeedback
            data={data}
            setResturant={setResturant}
            setData={setData}
            setPoistion={setPosition}
            resturant={resturant}
            setMarkers={setMarkers}
            setLoader={setLoader}
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
