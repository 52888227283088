/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { apiAxiosInstance } from "../utlis/http";
import toast, { LoaderIcon } from "react-hot-toast";
import FriendsList from "./FriendsList";
import FriendRequests from "./FriendRequests";
import PendingRequests from "./PendingRequests";
import { HiUserAdd } from "react-icons/hi";
import { FaUserClock, FaUserCheck } from "react-icons/fa6";

const ChatUser = () => {
  const [isActive, setIsActive] = useState("friends");
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [friends, setFriends] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [requests, setRequests] = useState([]);
  const [pages, setPages] = useState(1);
  const [totalpages, setTotalPages] = useState(1);

  const searchUsers = (value) => {
    setLoader(true);
    apiAxiosInstance
      .get(`search_users/?term=${value || ""}&page_count=${pages}`)
      .then((response) => {
        setTotalPages(response.total_pages);
        setUsers(response.user_data || []);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const addFriend = (id) => {
    apiAxiosInstance
      .post(`friends/${id}`)
      .then(() => {
        toast.success("Friend Request Sent Successfully", {
          duration: 5000,
          position: "top-right",
        });
        refreshData(); // Refresh all data after adding a friend
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const AcceptRequest = (id) => {
    apiAxiosInstance
      .put(`friends/${id}`)
      .then(() => {
        toast.success("Friend Request Sent Successfully", {
          duration: 5000,
          position: "top-right",
        });
        refreshData(); // Refresh all data after adding a friend
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFriends = () => {
    setLoader(true);
    apiAxiosInstance
      .get(`friends`)
      .then((response) => {
        setFriends(response || []);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const getPendingRequests = () => {
    setLoader(true);
    apiAxiosInstance
      .get(`pending_friends/inbound`)
      .then((response) => {
        setPendingRequests(response || []);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const getOutgoingRequests = () => {
    setLoader(true);
    apiAxiosInstance
      .get(`pending_friends/outbound`)
      .then((response) => {
        setRequests(response || []);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const refreshData = () => {
    getFriends();
    getPendingRequests();
    getOutgoingRequests();
    searchUsers(searchTerm);
  };

  useEffect(() => {
    refreshData();
  }, [pages]); // Runs on component mount and page change
  const pagination = () => {
    const pageNumbers = [];

    for (let index = 1; index <= totalpages; index++) {
      pageNumbers.push(
        <li key={index}>
          <div
            onClick={() => setPages(index)} // Set to the exact page number
            className={`flex items-center justify-center px-4 h-10 leading-tight border border-gray-300 cursor-pointer ${
              pages === index
                ? "bg-[#38BF86] text-white"
                : " text-gray-500 bg-white"
            }`}
          >
            {index}
          </div>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex justify-center items-center">
      <div className="container">
        <div className="bg-white rounded-lg py-4 px-4">
          <h1 className="text-[#38BF86] text-xl font-bold mb-2">Add Friends</h1>
          <div className="grid grid-cols-12 gap-4">
            <div className="md:col-span-8 col-span-12">
              <div className="flex">
                <label
                  htmlFor="search-dropdown"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only"
                >
                  User Name
                </label>
                <div className="relative w-full">
                  <input
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                      searchUsers(event.target.value);
                    }}
                    type="search"
                    id="search-dropdown"
                    className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-e-lg border border-gray-300 focus:ring-blue-500 focus:border-[#38BF86] focus-visible:border-[#38BF86]"
                    placeholder="Search your friend"
                    value={searchTerm}
                    required
                  />
                </div>
              </div>
              <div className="bg-[#F8F8F8] p-5 rounded-b-lg">
                {loader ? (
                  <div className="flex justify-center">
                    <LoaderIcon className="!w-10 !h-10 !p-3" />
                  </div>
                ) : (
                  users?.map((user) => (
                    <div key={user.id} className="mb-5">
                      <div className="flex justify-between">
                        <div className="flex items-center gap-3">
                          <div className="border content-center rounded-full h-10 w-10 bg-[#38BF86] text-white text-center shadow-lg uppercase">
                            {user.name.substring(0, 2)}
                          </div>
                          <h1 className="text-[#38BF86] font-bold">
                            {user?.name}
                          </h1>
                        </div>
                        {user.friend_status === "Not friends" ? (
                          <button
                            onClick={() => addFriend(user.user_name)}
                            className="rounded-[25px] px-4 py-2 font-bold bg-[#38BF86] flex justify-center items-center flex-wrap gap-2 text-white"
                          >
                            Add Friend <HiUserAdd size={20} />
                          </button>
                        ) : user.friend_status === "Pending" ? (
                          <button className="rounded-[25px] px-4 py-2 font-bold bg-[#38BF86] flex justify-center items-center flex-wrap gap-2 text-white">
                            Request Sent <FaUserClock size={20} />
                          </button>
                        ) : (
                          user.friend_status === "Friends" && (
                            <button className="rounded-[25px] px-4 py-2 font-bold bg-[#38BF86] flex justify-center items-center flex-wrap gap-2 text-white">
                              Friend <FaUserCheck size={20} />
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  ))
                )}

                <nav className="flex justify-center mt-5">
                  <ul className="inline-flex -space-x-px text-base h-10">
                    <li>
                      <button
                        disabled={pages === 1}
                        onClick={() => setPages(pages - 1)}
                        className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-white bg-[#38BF86] border border-e-0 border-gray-300 rounded-s-lg hover:bg-[#39a075]  hover:text-white"
                      >
                        Previous
                      </button>
                    </li>
                    {pagination()}
                    <li>
                      <button
                        disabled={pages === totalpages}
                        className="flex items-center justify-center px-4 h-10 leading-tight text-white bg-[#38BF86] border border-gray-300 rounded-e-lg hover:bg-[#39a075] hover:text-white"
                        onClick={() => setPages(pages + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="bg-[#38BF86] pt-2 flex flex-col rounded-b-3xl gap-2 rounded-t-2xl">
                <div className="flex flex-wrap gap-3 px-10 py-2 justify-between">
                  <h1
                    onClick={() => setIsActive("friends")}
                    className={`${
                      isActive === "friends" ? "text-white" : "text-gray-700"
                    } text-lg font-semibold cursor-pointer`}
                  >
                    Friends({friends.length})
                  </h1>
                  <h1
                    onClick={() => setIsActive("Pending")}
                    className={`${
                      isActive === "Pending" ? "text-white" : "text-gray-700"
                    } text-lg font-semibold cursor-pointer`}
                  >
                    Pending({pendingRequests.length})
                  </h1>
                  <h1
                    onClick={() => setIsActive("Request")}
                    className={`${
                      isActive === "Request" ? "text-white" : "text-gray-700"
                    } text-lg font-semibold cursor-pointer`}
                  >
                    Request({requests.length})
                  </h1>
                </div>
                {isActive === "friends" ? (
                  <FriendsList friends={friends} loader={loader} />
                ) : isActive === "Pending" ? (
                  <PendingRequests
                    pendingRequests={pendingRequests}
                    loader={loader}
                    addFriend={AcceptRequest}
                  />
                ) : (
                  isActive === "Request" && (
                    <FriendRequests friendRequests={requests} loader={loader} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatUser;
