import React from "react";

const Footer = () => {
  const date = new Date();
  return (
    <div className="w-full  z-10">
      <div className="bg-[#4bc592] text-white px-5 py-[4px] text-center text-[12px] lg:text-[16px] font-[400]">
        <p>{date.getFullYear()} Loop</p>
      </div>
    </div>
  );
};

export default Footer;
