import React from "react";
import { LoaderIcon } from "react-hot-toast";

const LoadingButton = ({ onClick, loading, type, children }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className="border border-1 border-gray-400 rounded-3xl w-full py-2 text-[15px] font-[400] md:py-2 md:text-[17px] lg:py-3 lg:text-xl lg:font-[400] mt-1 hover:bg-gray-300 hover:text-white hover:font-[500] "
    >
      {loading ? (
        <div className="items-center flex justify-center gap-1">
          <LoaderIcon className="!w-6 !h-6 " />
          Loading...
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;
