import React from "react";
import { ratedStars } from "../utlis/ratingStars";

const Restaurant = ({ data }) => {
  const { reviews } = data;
  return reviews?.map((review, index) => {
    const allRating = review.price + review.vibe + review.food;
    const averageRating = allRating / 3;
    const { first_name, last_name } = review;
    return (
      <div className="w-full flex flex-col items-start gap-4 px-0" key={index}>
        <div>
          <div className="flex items-center gap-3">
            <div className="border rounded-full h-10 w-10 bg-[#38BF86] text-white text-center content-center shadow-lg uppercase">
              {first_name.substring(0, 1)}
              {last_name.substring(0, 1)}
            </div>
            <h1 className="text-[#4bc592] text-[19px] lg:text-[20px] font-[400]">
              {first_name} {last_name}
            </h1>
          </div>
        </div>
        <div className="flex gap-3 lg:gap-4 w-full">
          <div className="flex flex-col gap-[1px] leading-none lg:gap-1 items-start">
            <div className="mt-1 flex items-center gap-2">
              <div className=" flex">{ratedStars(averageRating)}</div>
              <span className="text-gray-400 text-[15px] lg:text-[17px]">
                ({allRating})
              </span>
            </div>
            <div className="mt-[8px] mb-[3px]">
              <span className="border border-1 py-[1px] px-1 rounded-lg border-gray-400 text-gray-500 text-[13px] font-[400] lg:font-[500] ">
                RESTAURANT
              </span>
            </div>
            <div>
              <p className="text-left mt-1 text-[13px] lg:text-[16px] leading-tight lg:leading-1 lg:mt-0">
                {review?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-300 mt-2"></div>
      </div>
    );
  });
};

export default Restaurant;
