/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { apiAxiosInstance } from "../utlis/http";
import AddReview from "./AddReview";
import Restaurant from "./Restaurant";

const SearchFeedback = ({
  setPoistion,
  resturant,
  setMarkers,
  data,
  setResturant,
  setLoader,
  setData,
}) => {
  const MAX_RETRIES = 1;
  let retryCount = 0;

  const getRestaurant = () => {
    setLoader(true); // Start loader

    apiAxiosInstance
      .get(`restaurant/${resturant?.place_id}`)
      .then((response) => {
        const imageUrl = `https://loop-s3-restaurant-thumbnail-store-develop.s3.eu-west-2.amazonaws.com/${response.google_id}.jpeg`;

        const img = new Image();
        img.src = imageUrl;
        img.crossOrigin = "Anonymous";
        img.onload = (res) => {
          setData(response);
          setLoader(false);
        };

        img.onerror = (error) => {
          console.log(error, "Image loading error");

          if (retryCount < MAX_RETRIES) {
            retryCount += 1;
            console.log(`Retrying... (${retryCount}/${MAX_RETRIES})`);

            setTimeout(() => {
              getRestaurant();
            }, 2000);
            setData(response);
            setLoader(false);
          } else {
            console.log("Failed to load image after max retries");
            setLoader(false);
            setData(response);
          }
        };
      })
      .catch((error) => {
        // API error handling
        console.log(error, "API request failed");
        setLoader(false); // Stop loader on API failure
      });
  };

  useEffect(() => {
    if (resturant) {
      getRestaurant();
    }
  }, [resturant]);

  const [, setQuery1] = useState("");

  const autoCompleteRef1 = useRef(null);
  let autoComplete1;

  const handleScriptLoad1 = (updateQuery1, autoCompleteRef1) => {
    autoComplete1 = new window.google.maps.places.Autocomplete(
      autoCompleteRef1.current,
      {
        types: [
          "restaurant",
          "cafe",
          "bakery",
          "bar",
          "food",
          // "meal_delivery",
          // "meal_takeaway",
          // "night_club",
        ],
      }
    );

    autoComplete1.addListener("place_changed", () => {
      handlePlaceSelect1(updateQuery1);
    });
  };

  const handlePlaceSelect1 = async (updateQuery1) => {
    const addressObject1 = await autoComplete1?.getPlace();
    if (!addressObject1 || !addressObject1.geometry) {
      console.error("Place selection returned no geometry.");
      return;
    }
    console.log(addressObject1, "<==== data form google api");
    const latestQuery1 = addressObject1.place_id || "";
    updateQuery1(latestQuery1);

    const latLng1 = {
      lat: Number(addressObject1.geometry.location.lat()),
      lng: Number(addressObject1.geometry.location.lng()),
    };
    setResturant({ ...resturant, place_id: latestQuery1 });
    setPoistion(latLng1);
  };

  useEffect(() => {
    handleScriptLoad1(setQuery1, autoCompleteRef1);
  }, [autoCompleteRef1]);
  return (
    <div className="relative lg:bg-[#ffffff] w-[100%] lg:w-[33%] flex flex-col items-center gap-8 pl-4 pr-3 lg:px-6 h-[85vh] lg:overflow-y-auto">
      <div className="bg-[#fafafa] flex flex-col gap-2 justify-start w-[98%] py-8 px-7 mt-6 rounded-lg">
        <h2 className="text-left text-[17px] lg:text-[19px]">Search</h2>

        <input
          type="text"
          ref={autoCompleteRef1}
          className="border-b-2 bg-transparent py-2 outline-0 w-full text-[14px] lg:text-[17px]"
          placeholder="Search for a restaurant"
        />
      </div>

      {/* <hr className="w-[75%] h-[2px] bg-gray-500 lg:bg-black" /> */}
      {data && (
        <>
          <div className="w-full ">
            <AddReview resturant={resturant} getRestaurant={getRestaurant} />
          </div>
          <hr className="w-[75%] h-[2px] lg:h-[5px] bg-gray-500 lg:bg-black" />

          <div className="flex flex-col items-center md:w-[100%] lg:flex lg:flex-col lg:items-start md:pl-2 gap-4 ">
            <div className="flex items-center gap-1">
              <h1 className="text-[#4bc592] text-[25px] font-[500] lg:text-[30px]">
                Feedback
              </h1>
              <span className="text-gray-400 text-[18px] lg:text-[21px]">
                ({data?.reviews?.length ?? 0})
              </span>
            </div>
            {data !== null && <Restaurant data={data} />}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchFeedback;
