import axios from "axios";
import toast from "react-hot-toast";

// Instance 1 for auth-related requests
const authAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
});

// Instance 2 for general API requests
const apiAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_URL,
});

// Add interceptors to both instances
const setupInterceptors = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      console.log(error);
      const Data = error?.response?.data;
      const errors = Data?.errors;
      console.log(error, "errors");
      if (errors) {
        for (const key in errors) {
          if (Object.hasOwnProperty.call(errors, key)) {
            toast.error(errors[key]);
          }
        }
      } else {
        toast.error(
          Data?.Message.replace(/(BadRequestError:|ConflictError: )/g, "") ||
            error.message
        );
      }
      if (error.message === "Network Error") {
        localStorage.clear();
        window.location.href = "/auth/login";
        toast.error("Unauthorized");
      }
      return Promise.reject(error);
    }
  );
};

setupInterceptors(authAxiosInstance);
setupInterceptors(apiAxiosInstance);

export { authAxiosInstance, apiAxiosInstance };
