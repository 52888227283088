/* eslint-disable react-hooks/exhaustive-deps */
import {
  GoogleMap,
  InfoWindow,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { fetchImageAsBase64 } from "../utlis/fetchImageAsBase64";
import { apiAxiosInstance } from "../utlis/http";
import { ratedStars } from "../utlis/ratingStars";

const Map = ({
  position,
  setResturant,
  resturant,
  markers,
  setMarkers,
  data,
  setData,
}) => {
  const [zoom, setZoom] = useState(3);
  const [prevZoom, setPrevZoom] = useState(3);
  const [map, setMap] = useState(null);
  const getResturants = async () => {
    try {
      const response = await apiAxiosInstance.get(`friends_ratings`);
      const restaurants = response;

      const updatedMarkers = await Promise.all(
        restaurants.map(async (mark) => {
          const imageUrl = `https://loop-s3-restaurant-thumbnail-store-develop.s3.eu-west-2.amazonaws.com/${mark.place_id}.jpeg`;
          console.log(imageUrl, "imageUrl");
          try {
            const base64String = await fetchImageAsBase64(imageUrl);
            return {
              ...mark,
              base64String,
              not_load: false,
              position: {
                lat: mark.latitude,
                lng: mark.longitude,
              },
            };
          } catch (error) {
            console.log(`Error processing image for ${mark.place_id}`, error);
            const base64String = await fetchImageAsBase64(
              `${window.location.href}assets/images/defaultloop.png`
            );
            return {
              ...mark,
              base64String: base64String,
              not_load: true,
              position: {
                lat: mark.latitude,
                lng: mark.longitude,
              },
            };
          }
        })
      );

      setMarkers(updatedMarkers);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    getResturants();
  }, [resturant]);

  const containerStyle = {
    width: "100%",
    height: "85vh",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyANek74tAZWSI00YdYhsZxTCTv1AHligbQ",
  });
  console.log(resturant, data, "rest");

  console.log(zoom, "setZoom");
  return (
    isLoaded && (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={zoom}
          onZoomChanged={() => {
            if (map) {
              setZoom(map.getZoom());
            }
          }}
          onLoad={(map) => {
            setMap(map);
          }}
        >
          {markers.map((mark, index) => (
            <React.Fragment key={index}>
              <OverlayView
                position={mark.position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  onClick={() => {
                    setPrevZoom(zoom); // Store the current zoom before opening the InfoWindow
                    setResturant(mark);
                    map.panTo(mark.position);
                    map.setZoom(15); // Adjust zoom level as needed
                  }}
                  className="marker-div w-10 h-10 rounded-full overflow-hidden cursor-pointer bg-[#38BF86]"
                >
                  <img
                    src={`https://loop-s3-restaurant-thumbnail-store-develop.s3.eu-west-2.amazonaws.com/${mark.place_id}.jpeg`}
                    onError={(e) => {
                      e.target.src = `${window.location.href}assets/images/defaultloop.png`;
                    }}
                    alt="Marker"
                    className="p-1 w-full h-full rounded-full object-cover"
                  />
                </div>
              </OverlayView>
              {data && (
                <InfoWindow
                  position={data.coordinates}
                  onCloseClick={() => {
                    setData(null);
                    getResturants();
                    map.setZoom(prevZoom); // Reset zoom level to previous zoom when closing
                  }}
                >
                  <div className="relative flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
                    <div className="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl">
                      <img
                        className="w-full"
                        src={`https://loop-s3-restaurant-thumbnail-store-develop.s3.eu-west-2.amazonaws.com/${data.google_id}.jpeg`}
                        alt="Resturants"
                        onError={(e) => {
                          e.target.src = `${window.location.href}assets/images/defaultloop.png`;
                        }}
                      />
                    </div>
                    <div className="mt-4 px-5 pb-5">
                      <h5 className="text-xl tracking-tight text-slate-900">
                        {data.display_name}
                      </h5>

                      {/* Address Section */}
                      <div className="mt-2 mb-5 flex gap-2">
                        <HiLocationMarker size={30} className="text-gray-600" />
                        <span className="text-xl font-bold text-slate-900">
                          {data.address}
                        </span>
                      </div>

                      {/* Price Level Section */}
                      {data.price_level && (
                        <div className="flex items-center gap-1 mb-3">
                          <span className="text-lg font-bold text-slate-900">
                            Price:
                          </span>
                          <span className="text-lg text-gray-600 flex">
                            {ratedStars(data.price_level)}{" "}
                            <span className="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
                              {data.price_level}
                            </span>{" "}
                          </span>
                        </div>
                      )}
                      {data.phone_number && (
                        <div className="flex items-center gap-1 mb-3">
                          <span className="text-lg font-bold text-slate-900">
                            Phone Number:
                          </span>
                          <span className="text-lg text-gray-600 flex">
                            {data.phone_number}
                          </span>
                        </div>
                      )}

                      {/* Website URL Section */}
                      {data.website && (
                        <div className="mb-3 text-end">
                          <a
                            href={data.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                          >
                            Visit Website
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </React.Fragment>
          ))}
        </GoogleMap>
      </>
    )
  );
};

export default Map;
