import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authAxiosInstance } from "../../utlis/http";
import LoadingButton from "../../Components/LoadingButton";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [reset, setReset] = useState(false);
  const [values, setValues] = useState({
    code: "",
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const SendCode = () => {
    setLoader(true);
    authAxiosInstance
      .post(`auth/forgot_password`, values)
      .then(() => {
        setReset(true);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  const ChangePassword = () => {
    setLoader(true);
    authAxiosInstance
      .post(`auth/confirm_forgot_password`, values)
      .then(() => {
        navigate("/auth/login");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <div
      className="signup bg-cover bg-center w-full min-h-screen flex items-center justify-center "
      style={{
        backgroundImage: `url('/assets/images/background.png')`,
        minHeight: "100vh",
        backgroundSize: "cover",
        width: "100%",
      }}
    >
      <div className="form-container relative bg-white w-[85%] md:w-[70%] lg:w-[40%] py-5 px-4 md:py-7 md:px-6 lg:py-8 lg:px-7 flex gap-1 md:gap-2 flex-col items-start rounded-xl my-5">
        <h3 className="font-semibold text-[20px] md:2xl lg:text-3xl">
          Forgot Password
        </h3>
        {reset && (
          <p className="text-center text-[12px] font-[400] md:text-md lg:text-lg lg:font-[400] text-gray-600 leading-tight lg:leading-tight w-full">
            OTP sent at your email <i>{values.email}</i>
          </p>
        )}
        <div className="w-full">
          <label className="text-gray-600 text-[14px] font-[400] md:text-[16px] lg:text-xl lg:font-[400] mt-1 ">
            Email
          </label>
          <input
            disabled={reset}
            name="email"
            type="email"
            onChange={handleChange}
            className="border border-1 border-gray-400 rounded-lg w-full py-2 px-2 text-[12px] font-[300] md:py-2 md:px-4 md:text-lg lg:py-3 lg:px-5 lg:text-xl lg:font-[400]"
          />
        </div>
        {!reset ? (
          <LoadingButton onClick={SendCode} loading={loader} type={"button"}>
            Send OTP
          </LoadingButton>
        ) : (
          <>
            <div className="w-full">
              <label className="text-gray-600 text-[14px] font-[400] md:text-[16px] lg:text-xl lg:font-[400] mt-1 ">
                OTP
              </label>
              <input
                name="code"
                type="tel"
                onChange={handleChange}
                className="border border-1 border-gray-400 rounded-lg w-full py-2 px-2 text-[12px] font-[300] md:py-2 md:px-4 md:text-lg lg:py-3 lg:px-5 lg:text-xl lg:font-[400]"
              />
            </div>
            <div className="w-full">
              <label className="text-gray-600 text-[14px] font-[400] md:text-[16px] lg:text-xl lg:font-[400] mt-1 ">
                New Password
              </label>
              <input
                name="password"
                type="password"
                onChange={handleChange}
                className="border border-1 border-gray-400 rounded-lg w-full py-2 px-2 text-[12px] font-[300] md:py-2 md:px-4 md:text-lg lg:py-3 lg:px-5 lg:text-xl lg:font-[400]"
              />
            </div>

            <LoadingButton
              onClick={ChangePassword}
              loading={loader}
              type={"button"}
            >
              Change Password
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
