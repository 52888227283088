import React, { useState } from "react";
import { renderStars } from "../utlis/ratingStars";
import { apiAxiosInstance } from "../utlis/http";
import toast, { LoaderIcon } from "react-hot-toast";

const AddReview = ({ resturant, getRestaurant }) => {
  const [loader, setLoader] = useState(false);
  const Reviews = () => {
    setLoader(true);
    apiAxiosInstance
      .post(`ratings`, ratings)
      .then((response) => {
        toast.success("Review submitted successfully", {
          duration: 5000,
          position: "top-right",
        });
        setRatings({
          google_id: resturant.place_id,
          food: 0,
          vibe: 0,
          price: 0,
          message: "",
        });
        setLoader(false);
        getRestaurant();
      })
      .catch((error) => {
        setLoader(false);
        console.log(error, "error");
      });
  };
  const [ratings, setRatings] = useState({
    google_id: resturant.place_id,
    food: 0,
    vibe: 0,
    price: 0,
    message: "",
  });

  return (
    <div className="bg-[#fafafa] rounded-lg py-4 px-4 lg:px-7">
      <div className="flex flex-col items-center lg:items-start">
        <h1 className="text-[18px] lg:text-[21px] font-[500] text-gray-600 mb-3">
          {resturant?.name}
        </h1>

        <div className="flex justify-between items-center w-full">
          <h2 className="text-[16px] text-gray-600 lg:text-[18px] font-[500]">
            Food
          </h2>
          <div className="flex gap-[1px]">
            {renderStars(setRatings, ratings.food, "food")}
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <h2 className="text-[16px] text-gray-600 lg:text-[18px] font-[500]">
            Atmosphere
          </h2>
          <div className="flex gap-[1px]">
            {renderStars(setRatings, ratings.vibe, "vibe")}
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <h2 className="text-[16px] text-gray-600 lg:text-[18px] font-[500]">
            Price
          </h2>
          <div className="flex gap-[1px]">
            {renderStars(setRatings, ratings.price, "price")}
          </div>
        </div>
        <textarea
          placeholder="Write your review"
          onChange={(e) => setRatings({ ...ratings, message: e.target.value })}
          value={ratings.message}
          rows={4}
          className="resize-none rounded-xl w-full my-3 text-[14px] py-2 px-3 outline-none shadow-md"
        ></textarea>

        <div className="w-full flex justify-center">
          <button
            disabled={loader}
            onClick={Reviews}
            className="bg-[#4bc592] text-white rounded-3xl text-[15px] lg:text-[18px] font-[400] py-[5px] px-4 mt-2 lg:py-2 lg:px-10"
          >
            {loader ? (
              <>
                <div className="items-center flex justify-center gap-1">
                  <LoaderIcon className="!w-6 !h-6 " />
                  Submitting...
                </div>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddReview;
