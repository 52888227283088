import { FaDollarSign } from "react-icons/fa"; // Using react-icons for better icons
import { FaBowlFood } from "react-icons/fa6";

export const renderStars = (setRatings, rating, category) => {
  const handleRatingChange = (category, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: value,
    }));
  };

  const getIcon = (category, filled) => {
    const commonClasses = "h-5 w-5 cursor-pointer";
    const filledClass = filled ? "text-yellow-300" : "text-gray-300";
    const filledClassPrice = filled ? "text-green-600" : "text-gray-300";
    const filledClassFood = filled ? "text-red-600" : "text-gray-300";

    if (category === "food") {
      return <FaBowlFood className={`${commonClasses} ${filledClassFood}`} />;
    }
    if (category === "vibe") {
      return (
        <svg
          aria-hidden="true"
          className={`${commonClasses} ${filledClass}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
        </svg>
      );
    }
    if (category === "price") {
      return <FaDollarSign className={`${commonClasses} ${filledClassPrice}`} />;
    }
    return null;
  };

  return (
    <div className="flex gap-1">
      {Array(5)
        .fill(0)
        .map((_, index) => {
          const starValue = index + 1;
          return (
            <div
              key={index}
              onClick={() => handleRatingChange(category, starValue)}
            >
              {getIcon(category, rating >= starValue)}
            </div>
          );
        })}
    </div>
  );
};

export const ratedStars = (rating) => {
  return Array(5)
    .fill(0)
    .map((_, index) => {
      const starValue = index + 1;
      return (
        <svg
          key={index}
          aria-hidden="true"
          className={`h-5 w-5 ${
            rating >= starValue ? "text-yellow-300" : "text-gray-300"
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
        </svg>
      );
    });
};
