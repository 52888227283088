import "./App.css";
import Signin from "./Pages/auth/signin";
import Signup from "./Pages/auth/signup";
import Home from "./Pages/Home";
import { Route, Routes } from "react-router-dom";
import RequiredAuth from "./utlis/RequiredAuth";
import SignupVerify from "./Pages/auth/signupverify";
import Chat from "./Pages/Chat";
import ForgotPassword from "./Pages/auth/forgotpass";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth/login" element={<Signin />} />
        <Route path="/auth/register" element={<Signup />} />
        <Route path="/auth/forgotPassword" element={<ForgotPassword />} />
        <Route
          path={`/auth/register_verfication/:email`}
          element={<SignupVerify />}
        />
        <Route
          path="/"
          element={
            <RequiredAuth>
              <Home />
            </RequiredAuth>
          }
        />
        <Route
          path="/chat"
          element={
            <RequiredAuth>
              <Chat />
            </RequiredAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
