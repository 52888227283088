import React, { useState } from "react";
import toast from "react-hot-toast";
import { FcGoogle } from "react-icons/fc";
import { IoMdMail } from "react-icons/io";
import { LiaTimesSolid } from "react-icons/lia";
import { SiFacebook } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "../../Components/LoadingButton";
import { authAxiosInstance } from "../../utlis/http";

const Signin = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const Login = (event) => {
    event.preventDefault();
    setLoader(true);
    authAxiosInstance
      .post(`auth/login`, values)
      .then((response) => {
        localStorage.setItem("token", response.IdToken);
        localStorage.setItem("user", JSON.stringify(response));
        toast.success("Login Successfully", {
          duration: 5000,
          position: "top-right",
        });
        navigate("/");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <div
      className="signup bg-cover bg-center w-full h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url('/assets/images/background.png')`,
        height: "100vh",
        width: "100%",
      }}
    >
      <form
        onSubmit={Login}
        className="form-container relative bg-white w-[85%] md:w-[70%] lg:w-[40%] py-5 px-4 md:py-7 md:px-6 lg:py-8 lg:px-7 flex gap-1 md:gap-2 flex-col items-start rounded-xl"
      >
        {/* <div className="profile w-4 h-4 md:w-5 md:h-5 lg:w-8 lg:h-8 rounded-full bg-gray-300"></div> */}
        <h3 className="font-semibold text-[20px] md:2xl lg:text-3xl">
          Welcome back!
        </h3>

        <label className="text-gray-600 text-[14px] font-[400] md:text-[16px] lg:text-xl lg:font-[400] mt-1">
          Email
        </label>
        <input
          required
          type="email"
          name="email"
          onChange={handleChange}
          className="border border-1 border-gray-400 rounded-lg w-full py-2 px-2 text-[12px] font-[300] md:py-2 md:px-4 md:text-lg lg:py-3 lg:px-5 lg:text-xl lg:font-[400]"
        />
        <label className="text-gray-600 text-[14px] font-[400] md:text-[16px] lg:text-xl lg:font-[400] mt-1">
          Password
        </label>
        <input
          required
          type="password"
          name="password"
          onChange={handleChange}
          className="border border-1 border-gray-400 rounded-lg w-full py-2 px-2 text-[12px] font-[300] md:py-2 md:px-4 md:text-lg lg:py-3 lg:px-5 lg:text-xl lg:font-[400]"
        />
        <div className="text-end w-full text-gray-600">
          {" "}
          <Link to={"/auth/forgotPassword"}>Forgot Password?</Link>{" "}
        </div>
        <LoadingButton loading={loader} type={"submit"}>
          Login
        </LoadingButton>
        <div className="flex gap-4 items-center justify-center w-full my-1">
          <hr className="w-full h-[2px] bg-gray-300" />
          <span className="text-[14px] md:text-[17px] lg:text-[20px]">OR</span>
          <hr className="w-full h-[2px] bg-gray-300" />
        </div>
        <button className="border border-1 border-black rounded-3xl w-full flex justify-center items-center gap-4 py-2 text-[15px] font-[400] md:py-2 md:text-[17px] lg:py-3 lg:text-xl lg:font-[400]">
          <IoMdMail className="h-4 w-4 lg:h-6 lg:w-6" />
          <span className="text-[15px] md:text-[17px] lg:text-xl lg:font-[400]">
            Continue with Email
          </span>
        </button>
        <button className="border border-1 border-black rounded-3xl w-full flex justify-center items-center gap-4 py-2 text-[15px] font-[400] md:py-2 md:text-[17px] lg:py-3 lg:text-xl lg:font-[400]">
          <SiFacebook
            style={{ color: "#4267B2", fontSize: "2rem" }}
            className="h-5 w-5 lg:h-7 lg:w-7"
          />
          <span className="text-[15px] md:text-[17px] lg:text-xl lg:font-[400]">
            Continue with Facebook
          </span>
        </button>
        <button className="border border-1 border-black rounded-3xl w-full flex justify-center items-center gap-4 py-2 text-[15px] font-[400] md:py-2 md:text-[17px] lg:py-3 lg:text-xl lg:font-[400]">
          <FcGoogle className="h-5 w-5 lg:h-7 lg:w-7" />
          <span className="text-[15px] md:text-[17px] lg:text-xl lg:font-[400]">
            Continue with Google
          </span>
        </button>
        {/* <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          useOneTap
          auto_select
        /> */}

        <div className="flex gap-1 mt-1">
          <span className="text-gray-700 text-[13px] md:text-[17px] lg:text-lg">
            Don't have an account?
          </span>
          <span className="underline underline-offset-2 text-[14px] md:text-[18px] lg:text-lg lg:font-[500] text-black">
            <Link to={"/auth/register"}>Sign up </Link>
          </span>
        </div>
        <LiaTimesSolid className="absolute top-3 right-2 h-4 w-5 lg:top-5 lg:right-5 lg:h-5 lg:w-6" />
      </form>
    </div>
  );
};

export default Signin;
